import { createSelector } from '@reduxjs/toolkit';

import {
  indexBy,
  map,
  values,
  reduce,
  prop,
  propOr,
  contains,
  propEq,
  find,
  filter,
  length,
  isEmpty, pick, pickBy,
} from 'ramda';
import { reducer as formReducer } from 'redux-form';
import moment from 'moment';
import menu, { actions as menuActions, selectCombinedMenu, selectMenu } from './menu';
import cart, {
  actions as cartActions,
  selectCart,
  selectCartItemsLength,
  selectMaxPromoFreeQtySubmitWarning,
  selectPromoFreeActive,
  selectShowMaxPromoFreeItemCountWarning,
} from './cart';
import user, {
  actions as userActions,
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
  selectLocationNumber,
  selectMinDeliveryAmount,
  selectMinPickupAmount,
  selectBypassBusinessRules,
  selectPickupLeadTime,
  selectDeliveryLeadTime,
  selectMaxDeliveryAmount,
  selectMaxPickupAmount,
  selectCateringPickupMOSettings,
  selectCateringDeliveryMOSettings,
  selectMaxSameDayOrderTotalAmountCents,
  selectSpecialLocationErrorMessage,
  isVcaUser,
  selectLookupLocationError,
} from './user';
import order, {
  actions as orderActions,
  selectDestination,
  selectDestinationValid,
  selectOrderForDXE,
  selectSubTotalAmount,
  selectTaxAmount,
  selectOrderIsLoading,
  selectEditMode,
  selectReorder,
  selectOrderError,
  selectDeliveryError,
  selectDeliveryAddressChanged,
  selectDeliveryAddressSubmitWarning,
  selectHasOrderUpdateErrors,
  keys,
  selectValidAddressError,
  selectDeliveryRangeWarning,
  selectDeliveryTip,
  selectTotalAmount,
  selectPaperGoodsYesOrNo,
  selectPaperGoodsOptions,
  selectIsEditCMTOrder,
} from './order';
import guest, {
  actions as guestActions,
  selectCfaGuestSubmitWarning,
  selectCfaOneGuestOriginalPhone,
  selectIsCfaOneGuestSelected,
  selectPayment,
  selectGuestErrors,
} from './guest';
import device, { actions as deviceActions } from './device';
import {
  selectDate,
  selectDateIsToday,
  selectDetailsFormValues,
  selectEventDetailsValid,
  selectFormSubmitWarning,
  selectGuestDetailsValid,
  selectNewPaymentMethod,
  selectPaymentForm,
  selectPaymentSubmitWarning,
  selectPaymentValid,
  selectPayWithVaultedCard,
  selectSecondaryContactSubmitWarning,
  selectSecondaryContactValid,
  selectSecondaryContactValues,
  selectTime,
} from './form';
import dashboard, { selectExportEntireDaysExcelError } from './dashboard';
import { formatPaper, formatPaperGoodsOptions, formatPrice } from '../util/format';
import { phoneRegex } from '../util/validate';
import { roundNumber } from '../util/utils';
import ooeConstants from '../constants';
import { submitWarningErrorMessages } from '../util/customerErrorMessages';
import { consolidateItems } from '../util/menuItemHelpers';

export default {
  cart,
  menu,
  user,
  order,
  guest,
  device,
  form: formReducer,
  dashboard,
};

export const combinedActionCreators = {
  ...cartActions,
  ...menuActions,
  ...userActions,
  ...orderActions,
  ...guestActions,
  ...deviceActions,
};

/**
 * Menu Selectors
 */

export const selectMenuWithMethods = createSelector(
  selectDestination,
  selectMenu,
  (method, menuState) => selectCombinedMenu(method, menuState),
);

export const selectAllSellableItems = createSelector(
  selectMenuWithMethods,
  reduce((all, category) => [...all, ...category.items], []),
);

const indexByTag = indexBy(prop('tag'));

export const selectIndexedSellableItems = createSelector(
  selectAllSellableItems,
  (sellableItems) => {
    // TODO: this should probably happen in the base menu selectors and just do the indexing here
    const allIndexedItems = map((sellableItem) => {
      let freeSauces;
      const reducedItems = reduce((all, category) => {
        // if free sauces — get max, but don't add to list
        if (category.sauceType === 'Free') {
          freeSauces = category.maximum;
          return [...all];
        } if (category.sauceType === 'Paid') {
          const o = map((c) => ({ ...c, isSauce: true }), category.items);
          return [...all, ...o];
        }

        if (category.comboTag) {
          const o = map((c) => ({ ...c, comboTag: category.comboTag }), category.items);
          return [...all, ...o];
        }

        return [...all, ...category.items];
      }, [], sellableItem.items);
      const indexedItems = indexByTag(reducedItems);
      return { ...sellableItem, indexedItems, freeSauces };
    }, sellableItems);
    return indexByTag(allIndexedItems);
  },
);

/**
 Map menu items with cart items
  */
const mapMenuItemsToCart = (menuItems, cartItems) => map((menuItem) => {
  const quantity = cartItems
    .reduce((acc, item) => (item.tag === menuItem.tag ? (acc + item.quantity) : 0), 0);
  return { ...menuItem, quantity };
})(menuItems);

export const selectMenuWithQuantities = createSelector(
  selectMenuWithMethods,
  selectCart,
  (categories, cartItems) => map((cat) => ({
    ...cat,
    items: mapMenuItemsToCart(cat.items, cartItems),
  }))(categories),
);

/**
 * Cart Selectors
 */

export const selectCartWithPrices = createSelector(
  selectIndexedSellableItems,
  selectCart,
  (flattenedMenu, cartItems) => {
    const cartItemsWithPrices = map((item) => {
      let selectedSide;
      let selectedDessert;
      const {
        tag, modifiers, selectedSideTag, selectedDessertTag,
      } = item;

      if (!flattenedMenu[tag]) return {};

      const { freeSauces, sideItems, dessertItems } = flattenedMenu[tag];
      let freeSaucesRemaining = freeSauces * item.quantity || 0;

      const calculateModQuantity = (mod) => {
        if (contains(mod.tag, ooeConstants.TOGGLEABLE_ITEM_TAGS)) {
          return mod.quantity * item.quantity;
        }
        return mod.quantity;
      };

      const calculateModPrice = (mod) => {
        const menuMod = flattenedMenu[tag].indexedItems[mod.tag];
        if (!menuMod || !Object.prototype.hasOwnProperty.call(menuMod, 'itemPrice')) {
          // eslint-disable-next-line no-console
          console.warn(`${tag} does not have ${mod.tag} as an option`);
          return 0;
        }
        const { itemPrice } = menuMod;
        const displayQuantity = calculateModQuantity(mod);

        if (menuMod.isSauce === true) {
          const paidQuantity = Math.max(displayQuantity - freeSaucesRemaining, 0);
          freeSaucesRemaining += -(displayQuantity - paidQuantity);
          return itemPrice * paidQuantity;
        }
        return itemPrice * displayQuantity;
      };

      if (selectedSideTag) {
        const foundSide = find(propEq('tag', selectedSideTag), sideItems) || sideItems[0];

        selectedSide = {
          ...foundSide,
          price: item.quantity * foundSide.itemPrice,
          displayQuantity: item.quantity,
        };
      }

      if (selectedDessertTag) {
        const foundDessert = find(propEq('tag', selectedDessertTag), dessertItems) || dessertItems[0];
        selectedDessert = {
          ...foundDessert,
          price: item.quantity * foundDessert.itemPrice,
          displayQuantity: item.quantity,
        };
      }

      return {
        ...flattenedMenu[tag],
        ...item,
        selectedSide,
        selectedDessert,
        modifiers: map((mod) => ({
          ...flattenedMenu[tag].indexedItems[mod.tag],
          ...mod,
          displayQuantity: calculateModQuantity(mod),
          price: calculateModPrice(mod),
        }), values(modifiers)),
        items: map((i) => ({
          ...i,
          quantity: propOr(0, 'quantity', modifiers[i.tag]),
        }), values(flattenedMenu[tag].indexedItems)),
        price: flattenedMenu[tag].itemPrice * item.quantity,
      };
    }, cartItems);
    return cartItemsWithPrices;
  },
);

function selectModifiersForLineItems(modifiers) {
  const vals = values(modifiers);
  return vals.map((mod) => {
    let action = 'ADD';
    if (mod.modifierType === 'RECIPE') {
      action = 'REMOVE';
    }

    return {
      action,
      itemTag: mod.tag,
      modifierId: mod.id,
      name: mod.name,
      quantity: mod.quantity,
      comboTag: mod.comboTag,
    };
  });
}

function mapCartItems(items, comboModifiers, selectedSide, selectedDessert, comboItemQty) {
  return items.map((item) => {
    let finalItem = { ...item };
    let comboItems;
    let { modifiers } = finalItem;
    const { dayPart } = finalItem;

    if (finalItem.comboItems) {
      comboItems = mapCartItems(
        finalItem.comboItems,
        finalItem.modifiers,
        finalItem.selectedSide,
        finalItem.selectedDessert,
        //hardcode combo item quantity because promo free doesn't work without it
        1,
      );
      modifiers = [];
    }

    if (selectedSide && finalItem.itemGroupType === 'Side') {
      finalItem = selectedSide;
    }

    if (selectedDessert && finalItem.itemType === 'DESSERTS_GROUP') {
      finalItem = selectedDessert;
    }

    if (comboModifiers) {
      modifiers = filter((val) => val.comboTag === finalItem.tag)(comboModifiers);
    }

    return {
      itemTag: finalItem.tag,
      quantity: finalItem.quantity || comboItemQty,
      name: finalItem.name,
      specialInstructions: finalItem.specialInstructions,
      modifiers: selectModifiersForLineItems(modifiers),
      comboItems,
      dayPart,
      promoFree: finalItem.promoFree,
    };
  });
}

export const selectCartForLineItems = createSelector(
  selectCartWithPrices,
  (cartItems) => mapCartItems(cartItems),
);

export const selectCartHasBreakfastItems = createSelector(
  selectCartWithPrices,
  (items) => length(items.filter((item) => item.dayPart === ooeConstants.DAY_PART_BREAKFAST)) > 0,
);

/**
 * Form selectors
 */
export const selectTimes = createSelector(
  selectBypassBusinessRules,
  selectDate,
  selectTime,
  selectCartHasBreakfastItems,
  selectCartItemsLength,
  (bypassBusinessRules, date, time, containsBreakfast, cartLength) => {
    let promiseDateTime;
    let promiseTimeType;
    let businessDate;
    const automaticTime = moment()
      .add(1, 'day')
      .hour(1)
      .minute(0)
      .second(0)
      .format(ooeConstants.MOMENT_INPUT_FORMAT.dateTime);
    if (date) {
      businessDate = moment(date).format(ooeConstants.MOMENT_INPUT_FORMAT.businessDate);
    }
    if (date && time) {
      // 2018-03-06T09:30:00
      const dateTime = moment(`${businessDate} ${time}`, 'YYYY-MM-DD hh:mma');
      promiseDateTime = dateTime.format(ooeConstants.MOMENT_INPUT_FORMAT.dateTime);
    } else if (bypassBusinessRules && !date) {
      promiseDateTime = automaticTime;
    } else if (bypassBusinessRules && date) {
      promiseDateTime = moment(date, ooeConstants.MOMENT_INPUT_FORMAT.dateTime)
        .hour(1)
        .format(ooeConstants.MOMENT_INPUT_FORMAT.dateTime);
    } else if (cartLength > 0) {
      promiseTimeType = containsBreakfast ? ooeConstants.EARLIEST_AVAILABLE_BREAKFAST
        : ooeConstants.EARLIEST_AVAILABLE;
      //this is added because an order api respond with an error if we send only promiseTimeType when the BBR off and item is added to the cart
      promiseDateTime = automaticTime;
    }
    return {
      promiseDateTime,
      promiseTimeType,
      businessDate,
      date,
      time,
    };
  },
);

export const selectEventDetails = createSelector(
  selectDetailsFormValues,
  selectTimes,
  selectPaperGoodsOptions,
  selectPaperGoodsYesOrNo,
  selectIsEditCMTOrder,
  (details, times, paperGoodsOptions, paperGoodsYesOrNo, isCMT) => {
    const {
      paperGoods,
      specialInstructions,
      guestCount,
      cateringReason,
    } = details;
    const {
      promiseDateTime,
      promiseTimeType,
      businessDate,
    } = times;
    const specialInstructionsMessage = !isCMT ? `${formatPaperGoodsOptions(paperGoodsOptions)} ${specialInstructions || ''}` : (specialInstructions || formatPaper(paperGoods));
    const paperGoodsBool = !isCMT ? (paperGoodsYesOrNo === 'yes') : paperGoods;
    return {
      specialInstructions: specialInstructionsMessage,
      paperGoods: paperGoodsBool,
      guestCount,
      businessDate,
      promiseDateTime,
      promiseTimeType,
      cateringReason,
    };
  },
);

export const selectSpecialInstructionMessage = createSelector(
  selectPaperGoodsOptions,
  selectDetailsFormValues,
  (paperGoods, details) => `${formatPaperGoodsOptions(paperGoods)} ${details?.specialInstructions || ''}`,
);

/**
 * Order Selectors
 */

export const selectOrderForAPI = createSelector(
  selectOrderForDXE,
  selectEventDetails, // eslint-disable-line no-use-before-define
  selectLocationNumber,
  selectCartForLineItems,
  selectBypassBusinessRules,
  selectSecondaryContactValues,
  selectDeliveryTip,
  (orderDetails, eventDetails, locationNumber, lineItems, bypassBusinessRules, secondaryContact, deliveryTip) => {
    const lineItemsToUse = values(lineItems).map((lineItem) => {
      let { modifiers } = lineItem;
      const { comboItems } = lineItem;
      if (lineItem.comboItems) {
        modifiers.forEach((mod) => {
          comboItems.forEach((comboItem, i) => {
            // istanbul ignore next
            if (mod.comboTag === comboItem.itemTag) {
              const newMods = comboItem.modifiers || [];
              if (!newMods.find((aMod) => aMod.itemTag)) {
                newMods.push(mod);
              }
              comboItems[i].modifiers = newMods;
            }
          });
        });
        modifiers = [];
      }
      return {
        ...lineItem,
        modifiers,
        comboItems,
      };
    });

    const isNotEmptyValues = Object.values(secondaryContact).some(x => x !== null && x !== '' && typeof x === 'string');

    const isObjectWithKeys = value => !!(
      value
      && typeof value === 'object'
      && value.firstName
      && value.lastName
      && value.phoneNumber
    );

    const secondaryContactIsValid = isObjectWithKeys(secondaryContact);

    let orderToSend = {
      ...orderDetails,
      ...eventDetails,
      locationNumber,
      lineItems: consolidateItems(lineItemsToUse),
      deliveryTip,
    };
    if (orderToSend.lineItems.length === 0) {
      orderToSend = { ...orderToSend, status: 'Cart', lineItems: undefined };
    }
    if (bypassBusinessRules) {
      orderToSend = {
        ...orderToSend,
        bypassBusinessRulesList: ['all'],
      };
    }
    if (secondaryContactIsValid && isNotEmptyValues) {
      orderToSend = {
        ...orderToSend,
        secondaryContact,
      };
    }
    return orderToSend;
  },
);

export const selectOrderWithPayment = createSelector(
  selectOrderForAPI,
  selectPayWithVaultedCard,
  selectNewPaymentMethod,
  selectPayment,
  (orderState, payWithVaultedCard, newPaymentMethod, payment) => {
    if (payWithVaultedCard) {
      return { ...orderState, payment, status: 'Submit' };
    } if (!newPaymentMethod) {
      return { ...orderState, payment: { paymentType: 'TO_BE_COLLECTED' }, status: 'Submit' };
    }
    return { ...orderState, status: 'Cart' };
  },
);

export const selectTaxAndTotal = createSelector(
  selectTaxAmount,
  selectSubTotalAmount,
  (tax = 0, subtotal = 0) => formatPrice(tax + subtotal),
);

export const selectEventSummaryValid = createSelector(
  selectEventDetailsValid,
  selectGuestDetailsValid,
  selectDestinationValid,
  (detailsValid, guestValid, destinationValid) => detailsValid && guestValid && destinationValid,
);

export const selectAllFormsValid = createSelector(
  selectEventSummaryValid,
  selectCartItemsLength,
  selectPaymentValid,
  (detailsAndGuest, cartLength, paymentValid) => paymentValid && detailsAndGuest && cartLength > 0,
);

export const selectDeliveryMinNotMet = createSelector(
  selectDestination,
  selectSubTotalAmount,
  selectMinDeliveryAmount,
  (destination, subtotal, minDeliveryAmount) => destination === 'Delivery' && (subtotal * 100) <= minDeliveryAmount,
);

export const selectPickupMinNotMet = createSelector(
  selectDestination,
  selectSubTotalAmount,
  selectMinPickupAmount,
  (destination, subtotal, minPickupAmount) => destination === 'Pickup' && (subtotal * 100) <= minPickupAmount,
);

export const selectMaxOrderAmount = createSelector(
  selectDestination,
  selectMaxDeliveryAmount,
  selectMaxPickupAmount,
  (destination, deliveryMax, pickupMax) => {
    let maxOrderAmount;
    if (destination === 'Delivery') {
      maxOrderAmount = deliveryMax;
    } else if (destination === 'Pickup') {
      maxOrderAmount = pickupMax;
    }
    return maxOrderAmount;
  },
);

export const selectHoursOfOperation = createSelector(
  selectDestination,
  selectCateringDeliveryMOSettings,
  selectCateringPickupMOSettings,
  (destination, cateringDelivery, cateringPickup) => {
    let serviceHours;
    if (destination === 'Delivery') {
      serviceHours = cateringDelivery?.hoursOfOperation;
    } else if (destination === 'Pickup') {
      serviceHours = cateringPickup?.hoursOfOperation;
    }
    return serviceHours;
  },
);

export const selectSpecialEvents = createSelector(
  selectDestination,
  selectCateringDeliveryMOSettings,
  selectCateringPickupMOSettings,
  (destination, cateringDelivery, cateringPickup) => {
    if (destination === 'Delivery') {
      return cateringDelivery?.specialEvents ?? [];
    }

    if (destination === 'Pickup') {
      return cateringPickup?.specialEvents ?? [];
    }
    return [];
  },
);

export const selectExceedsMaxOrderTotal = createSelector(
  selectSubTotalAmount,
  selectMaxOrderAmount,
  (subtotal, maxOrderTotalAmount) => (subtotal * 100) > maxOrderTotalAmount,
);

export const selectExceedsSameDayMaxOrderTotal = createSelector(
  selectSubTotalAmount,
  selectMaxSameDayOrderTotalAmountCents,
  selectDateIsToday,
  (subtotal, maxSameDayOrderTotalAmount, dateIsToday) => dateIsToday && (subtotal * 100) > maxSameDayOrderTotalAmount,
);

export const selectSubtotalSubmitWarning = createSelector(
  selectDeliveryMinNotMet,
  selectMinDeliveryAmount,
  selectPickupMinNotMet,
  selectMinPickupAmount,
  selectExceedsMaxOrderTotal,
  selectMaxOrderAmount,
  selectExceedsSameDayMaxOrderTotal,
  selectMaxSameDayOrderTotalAmountCents,
  (
    deliveryMinNotMet,
    minDeliveryAmount,
    pickupMinNotMet,
    minPickupAmount,
    excedesMax,
    maxAmount,
    excedesMaxSameDay,
    maxSameDayAmount,
  ) => {
    if (excedesMax) {
      return {
        type: 'warning',
        message: `${submitWarningErrorMessages.ORDER_MAX}${formatPrice(maxAmount / 100)}.`,
      };
    }
    if (excedesMaxSameDay) {
      return {
        type: 'warning',
        message: `${submitWarningErrorMessages.SAME_DAY_ORDER_MAX}${formatPrice(maxSameDayAmount / 100)}.`,
      };
    }
    if (deliveryMinNotMet) {
      return {
        type: 'warning',
        message: `${submitWarningErrorMessages.DELIVERY_MIN}${formatPrice(minDeliveryAmount / 100)}.`,
      };
    }
    if (pickupMinNotMet) {
      return {
        type: 'warning',
        message: `${submitWarningErrorMessages.PICKUP_MIN}${formatPrice(minPickupAmount / 100)}.`,
      };
    }
    return {};
  },
);

export const selectSubmitButtonIsDisabled = createSelector(
  selectDeliveryMinNotMet,
  selectPickupMinNotMet,
  selectExceedsMaxOrderTotal,
  selectAllFormsValid,
  selectBypassBusinessRules,
  selectOrderIsLoading,
  selectDestinationValid,
  selectHasOrderUpdateErrors,
  selectValidAddressError,
  selectDeliveryRangeWarning,
  selectDeliveryAddressChanged,
  selectExceedsSameDayMaxOrderTotal,
  selectSecondaryContactValid,
  selectShowMaxPromoFreeItemCountWarning,
  (
    deliveryMinNotMet,
    pickupMinNotMet,
    exceedsMaxOrderTotal,
    allFormsValid,
    bypassBusinessRules,
    orderIsLoading,
    addressValid,
    hasOrderUpdateErrors,
    validAddressError,
    deliveryRangeWarning,
    deliveryAddressChanged,
    excedesMaxSameDay,
    secondaryContactValid,
    showMaxPromoFreeItemCountWarning,
  ) => {
    const disabledByBusinessRules = deliveryMinNotMet || pickupMinNotMet || exceedsMaxOrderTotal || excedesMaxSameDay;
    const disabledRegardlessOfBusinessRules = (
      hasOrderUpdateErrors
      || !allFormsValid
      || orderIsLoading
      || (!!validAddressError && deliveryAddressChanged)
      || !secondaryContactValid
      || showMaxPromoFreeItemCountWarning
    );

    if (bypassBusinessRules) {
      // CMT-941 - the fact that !addressValid is only checked
      // when bypassing business rules is probably a bug
      return disabledRegardlessOfBusinessRules || !addressValid;
    }
    return disabledRegardlessOfBusinessRules || disabledByBusinessRules;
  },
);

export const selectDestinationNotValidForLocation = createSelector(
  selectDestination,
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
  (destination, acceptsDelivery, acceptsPickup) => (
    (destination === ooeConstants.DELIVERY && !acceptsDelivery)
    || (destination === ooeConstants.PICKUP && !acceptsPickup)),
);

export const selectDisableGuestFields = createSelector(
  selectEditMode,
  selectIsCfaOneGuestSelected,
  (editMode, cfaOne) => editMode || cfaOne,
);

export const selectDisableGuestPhone = createSelector(
  selectEditMode,
  selectCfaOneGuestOriginalPhone,
  selectReorder,
  (editMode, phone, reorder) => (editMode || reorder) || ((phone !== '+1 111 111 1111') && phoneRegex.test(phone)),
);

export const selectErrorsAndWarnings = createSelector(
  selectOrderError,
  selectDeliveryError,
  selectGuestErrors,
  selectExportEntireDaysExcelError,
  selectSpecialLocationErrorMessage,
  selectLookupLocationError,
  (orderError, deliveryError, guestErrors, excelError, specialLocationErrorMessage, lookupLocationError) => {
    let errors = pick([
      keys.UPDATE_ORDER,
      keys.SUBMIT_ORDER,
      keys.EDIT_ORDER,
    ], orderError);
    const deliveryErrorMessage = deliveryError && `${deliveryError.type}: ${deliveryError.message}`;
    errors = {
      ...errors,
      deliveryErrorMessage,
      ...guestErrors,
      excelError,
      specialLocationErrorMessage,
      lookupLocationError,
    };

    // Remove null errors
    const notNull = (val) => val !== null;
    return pickBy(notNull, errors);
  },
);

export const selectLeadTimeWarning = createSelector(
  selectDestination,
  selectPickupLeadTime,
  selectDeliveryLeadTime,
  (destination, pickupLeadTime, deliveryLeadTime) => {
    if (destination === ooeConstants.DELIVERY) {
      return {
        type: 'warning',
        message: deliveryLeadTime,
      };
    }
    return {
      type: 'warning',
      message: pickupLeadTime,
    };
  },
);

export const selectAllSubmitWarnings = createSelector(
  selectSubtotalSubmitWarning,
  selectFormSubmitWarning,
  selectDeliveryAddressSubmitWarning,
  selectPaymentSubmitWarning,
  selectCfaGuestSubmitWarning,
  selectLeadTimeWarning,
  selectSecondaryContactSubmitWarning,
  selectMaxPromoFreeQtySubmitWarning,
  (
    subtotalWarnings,
    formWarnings,
    destinationWarning,
    paymentWarning,
    cfaGuestWarning,
    leadTime,
    secondaryContact,
    promoFreeMaxQty,
  ) => [
    subtotalWarnings,
    formWarnings,
    destinationWarning,
    paymentWarning,
    cfaGuestWarning,
    leadTime,
    secondaryContact,
    promoFreeMaxQty,
  ].filter((warning) => !isEmpty(warning)),
);

export const selectAllDayChickenMinis = createSelector(
  selectLocationNumber,
  (locationNumber) => {
    let message = '';
    if (ooeConstants.ALL_DAY_CHICKEN_MINI_LOCATIONS.includes(locationNumber)) {
      message = 'All day Chicken Minis';
    } else {
      switch (locationNumber) {
        case '02322':
          message = 'Only the Tray Minis available all day';
          break;
        case '02013':
          message = 'All day Chicken Minis for 20ct and 40ct';
          break;
        case '03489':
          message = 'All day Chicken Minis for catering trays only';
          break;
        case '03003':
          message = 'All day Chicken Minis in tray sizes only (no 4 count all day)';
          break;
        case '00316':
          message = 'Can call the store ahead of time to check for Chicken Minis.';
          break;
        case '02926':
          message = 'Can we do only Large Mini Trays after 10:00AM.';
          break;
        case '03022':
          message = 'All day Chicken Minis if ordered in advance (by 10:30 am).';
          break;
        case '01546':
          message = 'All day Chicken Minis only for Small and Large Mini Trays';
          break;
        case '01157':
          message = 'Mini Trays are allowed with minimum of 1 hour advanced notice';
          break;
        case '03606':
          message = 'Chicken Mini Trays are available all day';
          break;
        case '03491':
          message = 'All day Chicken Minis, but needs a phone call to the store informing the staff and needs at least 2 hours notice before ordering';
          break;
        case '00618':
          message = 'All day Chicken Minis, but call restaurant first to provide a heads up';
          break;
        default:
          message = '';
      }
    }
    return message;
  },
);

export const selectStoreInfoSpecialMessage = createSelector(
  selectLocationNumber,
  (locationNumber) => {
    const messages = ooeConstants.SPECIAL_LOCATION_STORE_INFO_MESSAGES;
    return messages[locationNumber] || '';
  },
);

export const selectPayLaterNotAllowed = createSelector(
  selectLocationNumber,
  isVcaUser,
  (locationNumber, isVcaBypass) => isVcaBypass && ooeConstants.PAY_LATER_NOT_ALLOWED_LOCATIONS.includes(locationNumber),
);

export const selectDeliveryTipIsDisplayed = createSelector(
  selectPaymentForm,
  selectDestination,
  selectLocationNumber,
  (payment, destination, locationNumber) => payment?.values?.selectedMethod === ooeConstants.CREDIT && destination === ooeConstants.DELIVERY && ooeConstants.AVAILABLE_DELIVERY_TIP_LOCATIONS.includes(locationNumber),
);

export const selectAllItemsPromoFree = createSelector(
  selectPromoFreeActive,
  selectTotalAmount,
  (promoFreeActive, totalAmount) => promoFreeActive && totalAmount === 0,
);

export const selectPromoFreeItemsValue = createSelector(
  selectCartWithPrices,
  (cartWithPrices) => {
    const promoFreeItemsValue = cartWithPrices
      .filter(x => x.promoFree)
      .reduce((accumulator, current) => accumulator + ((current.itemPrice * current.quantity) || 0),
        0);
    return roundNumber(promoFreeItemsValue);
  },
);
