import 'react-app-polyfill/ie11';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { FeatureFlagService, config } from '@cfacorp/web-pos-services';
import { setupAmplitude } from './services/amplitude';
import { BrowserRouter } from './util/history';
import './index.css';
import ConnectedApp from './App';
import ooeConstants from './constants';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import initializeStore from './initializeStore';
import redirectToHttpsIfNecessary from './util/redirectToHttpsIfNecessary';
import getBugSnagStateData from './util/bugSnagStateData';
import {
  setupBugsnag,
  leaveBreadcrumb,
  initializeGetBugSnagStateData,
} from './services/bugsnag';
import { BugsnagErrorBoundary, UnrecoverableError } from './components';
import ReduxStore from './util/reduxStore';

redirectToHttpsIfNecessary({
  location: window.location,
  redirectToUrl: url => {
    window.location.href = url;
  },
});

const store = initializeStore();
ReduxStore.store = store;

config.setConfig({
  ENVIRONMENT: window.Cypress ? 'qa' : ooeConstants.ENV,
  IS_PILOT: ooeConstants.ENVIRONMENT_DATA.IS_PILOT,
  DXE_SERVICES_BASE: ooeConstants.DXE_SERVICES_BASE,
  D2C_SERVICES_BASE: ooeConstants.D2C_SERVICES_BASE,
});
try {
  FeatureFlagService.initialize(ooeConstants.URL.CMT_CONFIG).subscribe();
} catch (e) {
  // eslint-disable-next-line no-console
  console.warn('FeatureFlagService.initialize error', e);
}

// useful for debugging deployed environments
/* eslint-disable no-console */
console.debug('__/__/__/__/__/__/__/__/__/__/__/__/__/__/__/');
console.debug(`process.env.NODE_ENV=${process.env?.NODE_ENV}`);
console.debug(`bugsnag release stage=${ooeConstants.BUGSNAG_RELEASE_STAGE}`);
console.debug('process.env=', process.env);
console.debug('window.location=', window.location);
console.debug('__/__/__/__/__/__/__/__/__/__/__/__/__/__/__/');
console.debug('');
/* eslint-enable no-console */

// set up bugsnag
initializeGetBugSnagStateData(getBugSnagStateData);
setupBugsnag(store);

leaveBreadcrumb('Application Startup', {
  message: 'Application initializing',
});

setupAmplitude();

const render = Component => {
  const root = createRoot(document.getElementById('root'));
  return root.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <BugsnagErrorBoundary FallbackComponent={UnrecoverableError}>
      <Provider store={store}>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
      </Provider>
    </BugsnagErrorBoundary>,
  );
};

render(ConnectedApp);

// Webpack Hot Module Replacement API
//asdf_temp comment, not suppored?
// if (module.hot) {
//   module.hot.accept('./App', () => {
//     render(ConnectedApp);
//   });
// }
unregisterServiceWorker();
