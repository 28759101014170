import { configureStore } from '@reduxjs/toolkit';

import { createEpicMiddleware } from 'redux-observable';
import rootReducer from './reducers';
import rootEpic from './epics';
import ooeConstants from './constants';

const epicMiddleware = createEpicMiddleware();

const initializeStore = () => {
  const newStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
  });

  epicMiddleware.run(rootEpic);

  if (process.env.NODE_ENV !== ooeConstants.PRODUCTION) {
    if (module.hot) {
      module.hot.accept('./reducers', () => newStore.replaceReducer(rootReducer));
      module.hot.accept('./epics', () => epicMiddleware.replaceEpic(rootEpic));
    }
  }

  return newStore;
};

export default initializeStore;
